import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class EsqueceuSenha extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }

        this.atribuiEmail = this.atribuiEmail.bind(this);
    }

    atribuiEmail(event) {
        this.setState({ email: event.target.value })
    }
    render() {
        return (
            <div className="divConteudo justify-content-center">
                <div className="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                    <div className="margin-interna">
                        <div className="card">
                            <div className="card-header">
                                <h5>Recuperar senha:</h5>
                            </div>
                            <div className="card-body">
                                <form autoComplete="off" className="card-text">
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <p className="card-title">Digite seu email:</p>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.atribuiEmail}
                                                className='form-control'
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-6 text-left">
                                            <Link to="/">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                                    Voltar
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="form-group col-6 text-right">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                                Continuar
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
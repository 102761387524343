import React, { Component } from 'react';
import logo from '../../Logo/logo_200.png';
import Modal from 'react-modal';
// import { Redirect, Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import {
    changeCurrentRole,
    changeConectado,
    changeUsername
} from '../actions/actions';
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Spinner } from 'reactstrap';
import CheckBox from 'react-simple-checkbox';

Modal.setAppElement('#root');

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            senha: '',
            redirect: false,
            erro: false,
            tipoErro: [],
            currentRole: '',
            loading: false,
            alteraSenha: ''
        }

        this.atribuiusername = this.atribuiusername.bind(this);
        this.atribuiSenha = this.atribuiSenha.bind(this);
        this.atribuiConectado = this.atribuiConectado.bind(this);

        this.entra = this.entra.bind(this);

        this.modalErro = this.modalErro.bind(this);
    }

    atribuiusername(e) {
        let username = e.target.value;

        this.props.changeUsername(username);
        this.setState({ username });
    }

    atribuiSenha(e) {
        let senha = e.target.value;

        this.setState({ senha })
    }

    atribuiConectado(conectado) {
        this.setState({ conectado });
        this.props.changeConectado(conectado);
    }

    entra(event) {
        this.setState({ loading: true });
        event.preventDefault();

        axios.defaults.withCredentials = true;

        axios.post(process.env.REACT_APP_URL_LOGIN,
            {
                UserName: this.props.username,
                Senha: this.state.senha
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    this.setState({
                        redirect: true,
                        loading: false,
                        alteraSenha: res.data.alteraSenha
                    })
                    let currentRole = res.data.role
                    this.props.changeCurrentRole(currentRole);
                }
                else {
                    let erro = this.state.erro;
                    erro = 'Login Inválido';
                    this.setState({
                        erro,
                        loading: false
                    });

                    let cookies = this.state.cookies;
                    cookies.remove('token');
                    cookies.remove('refreshToken');
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    this.setState({
                        tipoErro: error.response.data === '' ? "Credenciais inválidas" : error.response.data,
                        loading: !this.state.loading
                    });
                    this.modalErro();
                }
                else {
                    this.setState({
                        tipoErro: "Não foi possível conectar com o servidor",
                        loading: !this.state.loading
                    });
                    this.modalErro();
                }
            });
    }

    modalErro() {
        this.setState({ erro: !this.state.erro })
    }

    render() {
        if (this.state.redirect) {
            if (this.state.alteraSenha === false) {
                return <Redirect to='/Home/Entidade' />
            }
            else {
                return <Redirect to='/NovaSenha' />
            }
        }
            return (
                <div>
                    <Modal  //Modal de Loading
                        isOpen={this.state.loading}
                        className="modal-sair col-6 col-md-5"
                        overlayClassName="overlay"
                    >
                        <div className="float-center text-center eventodiv">
                            <label> Conectando... </label><br />
                            <Spinner color="primary" size="lg" />
                        </div>
                    </Modal>
                    <Modal  //Modal de erro
                        isOpen={this.state.erro}
                        className="modal-sair col-6 col-md-5"
                        overlayClassName="overlay"
                    >
                        <div className="float-center text-center eventodiv">
                            <label> Ops! Temos problemas: </label><br />
                            {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                                <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                            <div className="row">
                                <div className="col">
                                    <button onClick={this.modalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div className="corFundoLogin">
                        <div className="row justify-content-center tamanhoRow h-75">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-4 my-auto">
                                <div className="card cardLogin">
                                    <div className="logo text-center">
                                        <img src={logo} alt="LOGO" className="col-6 col-sm-5 col-md-5 col-lg-5 col-x-3" />
                                    </div>

                                    <div className="card-body">
                                        <form onSubmit={this.entra} autoComplete="off">
                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label> Usuário: </label>
                                                    <input
                                                        type="text"
                                                        id="username"
                                                        name="username"
                                                        value={this.props.username}
                                                        className='form-control'
                                                        onChange={this.atribuiusername}
                                                        required />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label> Senha: </label>
                                                    <input
                                                        type="password"
                                                        id="senha"
                                                        name="senha"
                                                        value={this.state.senha}
                                                        className='form-control'
                                                        onChange={this.atribuiSenha}
                                                        required
                                                        autoComplete="off" />
                                                </div>
                                            </div>

                                            <div className="form-row margemBottom">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <CheckBox
                                                            id='conectado'
                                                            size={4}
                                                            tickSize={3}
                                                            borderThickness={1}
                                                            checked={this.props.conectado}
                                                            onChange={this.atribuiConectado}
                                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                                            tickAnimationDuration={100}
                                                            backAnimationDuration={100} />
                                                        <label htmlFor='conectado' className="alinhamentoCheck align-middle">Mantenha-me conectado</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-sm col-9 col-sm-8 col-md-7 col-lg-6 col-xl-5">
                                                        Entrar
                                                </button>
                                                </div>
                                            </div>
                                            {/* <p className="card-text">Esqueceu a senha? <Link to="/EsqueceuSenha">Clique aqui</Link></p> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const mapStateToProp = (state) => ({
        currentRole: state.dadosRole.currentRole,
        conectado: state.dadosRole.conectado,
        username: state.dadosRole.username
    });

    const mapActionstoProps = {
        changeCurrentRole,
        changeConectado,
        changeUsername
    }

    export default connect(mapStateToProp, mapActionstoProps)(Login);
export const changeQuantidadeEmpresaHsCom = (quantidadeEmpresasHsCom) => {
    return {
        type: 'changeQuantidadeEmpresaHsCom',
        payload: { quantidadeEmpresasHsCom }
    }
}

export const changeQuantidadeFuncionarioHsCom = (quantidadeFuncionariosHsCom) => {
    return {
        type: 'changeQuantidadeFuncionarioHsCom',
        payload: { quantidadeFuncionariosHsCom }
    }
}

export const changeQuantidadeUsuarioHsCom = (quantidadeUsuariosHsCom) => {
    return {
        type: 'changeQuantidadeUsuarioHsCom',
        payload: { quantidadeUsuariosHsCom }
    }
}

export const changeQuantidadeEquipamentoHsCom = (quantidadeEquipamentosHsCom) => {
    return {
        type: 'changeQuantidadeEquipamentoHsCom',
        payload: { quantidadeEquipamentosHsCom }
    }
}

export const changeDataFimLicencaHsCom = (dataFimLicencaHsCom) => {
    return {
        type: 'changeDataFimLicencaHsCom',
        payload: { dataFimLicencaHsCom }
    }
}

export const changeSituacaoHsCom = (situacaoHsCom) => {
    return {
        type: 'changeSituacaoHsCom',
        payload: { situacaoHsCom }
    }
}
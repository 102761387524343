export const changeNomeEntidade = (nomeEntidade) => {
    return {
        type: 'changeNomeEntidade',
        payload: { nomeEntidade }
    }
}

export const changeEmailEntidade = (emailEntidade) => {
    return {
        type: 'changeEmailEntidade',
        payload: { emailEntidade }
    }
}

export const changeCnpjEntidade = (cnpj) => {
    return {
        type: 'changeCnpjEntidade',
        payload: { cnpj }
    }
}

export const changeInicioContratoEntidade = (inicioContrato) => {
    return {
        type: 'changeInicioContratoEntidade',
        payload: { inicioContrato }
    }
}

export const changeRecuperaUsuarioEntidade = (recuperaUsuario) => {
    return {
        type: 'changeRecuperaUsuarioEntidade',
        payload: { recuperaUsuario }
    }
}

export const changeIdEntidade = (idEntidade) => {
    return {
        type: 'changeIdEntidade',
        payload: { idEntidade }
    }
}

export const changeIdAcessoEntidade = (idEntidadeAcesso) => {
    return {
        type: 'changeIdAcessoEntidade',
        payload: { idEntidadeAcesso }
    }
}
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TabelaUsuario from './tabela_usuario'; 
import NavbarRotaUsuario from './navBar_rotaUsuario';

const RotasBotaoUsuario = () => (
    <Switch>
        <Route exact path='/Home/Usuario' component={TabelaUsuario} />
        <Route path='/Home/Usuario/NovoUsuario' component={NavbarRotaUsuario} />
    </Switch>
);

export default RotasBotaoUsuario;
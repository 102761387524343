import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
    changeQuantidadeEmpresaHsMobile,
    changeQuantidadeFuncionarioHsMobile,
    changeQuantidadeUsuarioHsMobile,
    changeDataFimLicencaHsMobile,
    changeSituacaoHsMobile
} from '../actions/actionEntidadeHsMobile';
import { TituloEntidade } from './tituloForm'

const ROLE_SYSTEM = "System";
const ROLE_ADM = "Admin";
const ROLE_User = "User";


Modal.setAppElement('#root');

class HsMobile extends Component {
    constructor(state) {
        super(state);
        this.state = {
            situacao: this.props.situacaoHsMobile,
            quantidadeEmpresas: this.props.quantidadeEmpresasHsMobile,
            quantidadeFuncionarios: this.props.quantidadeFuncionariosHsMobile,
            quantidadeUsuarios: this.props.quantidadeUsuariosHsMobile,
            dataFimLicenca: this.props.dataFimLicencaHsMobile
        }

        this.atribuiSituacao = this.atribuiSituacao.bind(this);
        this.atribuiEmpresas = this.atribuiEmpresas.bind(this);
        this.atribuiFuncionarios = this.atribuiFuncionarios.bind(this);
        this.atribuiUsuarios = this.atribuiUsuarios.bind(this);
        this.atribuiDataFimLicenca = this.atribuiDataFimLicenca.bind(this);
    }

    verificaRole() {
        switch (this.props.currentRole) {
            case ROLE_SYSTEM:
                return true;
            case ROLE_ADM:
                return true;
            case ROLE_User:
                return false;
            default:
                return false
        }
    }

    atribuiSituacao(event){
        let situacao = event.target.value;

        this.setState({ situacao });
        this.props.changeSituacaoHsMobile(situacao)
    }

    atribuiEmpresas(event){
        let quantidadeEmpresas = event.target.value;

        this.setState({ quantidadeEmpresas });
        this.props.changeQuantidadeEmpresaHsMobile(quantidadeEmpresas)
    }

    atribuiFuncionarios(event){
        let quantidadeFuncionarios = event.target.value;

        this.setState({ quantidadeFuncionarios });
        this.props.changeQuantidadeFuncionarioHsMobile(quantidadeFuncionarios)
    }

    atribuiUsuarios(event){
        let quantidadeUsuarios = event.target.value;

        this.setState({ quantidadeUsuarios });
        this.props.changeQuantidadeUsuarioHsMobile(quantidadeUsuarios)
    }

    atribuiDataFimLicenca(event){
        let dataFimLicenca = event.target.value;

        this.setState({ dataFimLicenca });
        this.props.changeDataFimLicencaHsMobile(dataFimLicenca)
    }
    
    render() {
        let disabled = this.verificaRole() ? false : true;
        return (
            <>
                <TituloEntidade titulo="HSMobile"/>
                <div className="form-row margemFormulario">
                    <div className="form-group col-12 col-sm-3">
                        <label> Situação: </label>
                        <select
                            disabled={disabled}
                            id='situacaoEntidade'
                            name='situacaoEntidade'
                            className={this.state.situacao === "Selecione" ? 'form-control is-invalid' : 'form-control'}
                            value={this.state.situacao}
                            onChange={this.atribuiSituacao}>
                            <option value={-1} disabled="disabled"> Selecione</option>
                            <option value='0'>Bloqueado</option>
                            <option value='1'>Liberado</option>
                        </select>
                        <div className='invalid-feedback'>
                            {this.state.situacao === "Selecione" ? "Selecione uma opção" : null}
                        </div>
                    </div>
                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Empresas: </label>
                        <input
                            disabled={disabled}
                            type="number"
                            id="quantidadeEmpresas"
                            name="quantidadeEmpresas"
                            min="0"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeEmpresas}
                            onChange={this.atribuiEmpresas} />
                    </div>

                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Funcionarios: </label>
                        <input
                            disabled={disabled}
                            type="number"
                            id="quantidadeFuncionarios"
                            name="quantidadeFuncionarios"
                            min="0"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeFuncionarios}
                            onChange={this.atribuiFuncionarios} />
                    </div>

                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Usuários: </label>
                        <input
                            disabled={disabled}
                            type="number"
                            id="quantidadeUsuarios"
                            name="quantidadeUsuarios"
                            min="0"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeUsuarios}
                            onChange={this.atribuiUsuarios} />
                    </div> 
                </div>
                <div className="form-row margemFormulario">
                    <div className="form-group col-12 col-sm-3">
                        <label> Data final da licença </label>
                        <input
                            type="date"
                            id="dataFimLicenca"
                            name="dataFimLicenca"
                            value={this.state.dataFimLicenca || ''}
                            onChange={this.atribuiDataFimLicenca}
                            className='form-control'
                            autoComplete='off'
                            disabled={disabled}
                        />
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProp = (state) => ({
    currentRole: state.dadosRole.currentRole,

    situacaoHsMobile: state.dadosEntidade.situacaoHsMobile,
    quantidadeEmpresasHsMobile: state.dadosEntidade.quantidadeEmpresasHsMobile,
    quantidadeFuncionariosHsMobile: state.dadosEntidade.quantidadeFuncionariosHsMobile,
    quantidadeUsuariosHsMobile: state.dadosEntidade.quantidadeUsuariosHsMobile,
    dataFimLicencaHsMobile: state.dadosEntidade.dataFimLicencaHsMobile,
});

const mapActionstoProps = {
    changeQuantidadeEmpresaHsMobile,
    changeQuantidadeFuncionarioHsMobile,
    changeQuantidadeUsuarioHsMobile,
    changeDataFimLicencaHsMobile,
    changeSituacaoHsMobile
}

export default connect(mapStateToProp, mapActionstoProps)(HsMobile);
const initialState = {
    currentRole: '',
    conectado: false,
    username: ''
}

const adicionaRole = (state = initialState, action) => {
    switch (action.type) {
        case 'changeCurrentRole':
            return { ...state, currentRole: action.payload.currentRole }

        case 'changeInitialState':
            return { ...state, currentRole: action.payload.currentRole }

        case 'changeLogout':
            return {
                ...state, conectado: action.payload.conectado,
                username: action.payload.username
            }

        case 'changeConectado':
            return { ...state, conectado: action.payload.conectado }

        case 'changeUsername':
            return { ...state, username: action.payload.username }

        default:
            return state;

    }
};

export default adicionaRole;
export const changeQuantidadeEmpresaHsMobile = (quantidadeEmpresasHsMobile) => {
    return {
        type: 'changeQuantidadeEmpresaHsMobile',
        payload: { quantidadeEmpresasHsMobile }
    }
}

export const changeQuantidadeFuncionarioHsMobile = (quantidadeFuncionariosHsMobile) => {
    return {
        type: 'changeQuantidadeFuncionarioHsMobile',
        payload: { quantidadeFuncionariosHsMobile }
    }
}

export const changeQuantidadeUsuarioHsMobile = (quantidadeUsuariosHsMobile) => {
    return {
        type: 'changeQuantidadeUsuarioHsMobile',
        payload: { quantidadeUsuariosHsMobile }
    }
}

export const changeDataFimLicencaHsMobile = (dataFimLicencaHsMobile) => {
    return {
        type: 'changeDataFimLicencaHsMobile',
        payload: { dataFimLicencaHsMobile }
    }
}

export const changeSituacaoHsMobile = (situacaoHsMobile) => {
    return {
        type: 'changeSituacaoHsMobile',
        payload: { situacaoHsMobile }
    }
}
import React, { Component } from 'react';
import RotasBotaoEntidade from './rotas_botaoEntidade';

export default class MainEntidade extends Component {
    render() {
        return (
            <div className="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                <div className="margin-interna">
                    <RotasBotaoEntidade />
                </div>
            </div>
        );
    }
}
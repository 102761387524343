import React, { Component } from 'react';
import NavbarEntidade from './botao_paraForm';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { GoPencil } from "react-icons/go";
import { Link, Redirect } from 'react-router-dom';
import paginate from 'paginate-array';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FaSistrix, FaDownload } from 'react-icons/fa';
import { MdNavigateBefore, MdLastPage, MdNavigateNext, MdFirstPage } from 'react-icons/md';
import {
    changeNomeEntidade,
    changeEmailEntidade,
    changeCnpjEntidade,
    changeInicioContratoEntidade,
    changeRecuperaUsuarioEntidade,
    changeIdEntidade,
    changeIdAcessoEntidade
} from '../actions/actionEntidade';
import {
    changeQuantidadeEmpresaHsMobile,
    changeQuantidadeFuncionarioHsMobile,
    changeQuantidadeUsuarioHsMobile,
    changeSituacaoHsMobile,
    changeDataFimLicencaHsMobile
} from '../actions/actionEntidadeHsMobile';

import {
    changeQuantidadeEmpresaHsPonto,
    changeQuantidadeFuncionarioHsPonto,
    changeQuantidadeUsuarioHsPonto,
    changeQuantidadeEquipamentoHsPonto,
    changeSituacaoHsPonto,
    changeDataFimLicencaHsPonto
} from '../actions/actionEntidadeHsPonto';

import {
    changeQuantidadeEmpresaHsCom,
    changeQuantidadeFuncionarioHsCom,
    changeQuantidadeUsuarioHsCom,
    changeQuantidadeEquipamentoHsCom,
    changeSituacaoHsCom,
    changeDataFimLicencaHsCom
} from '../actions/actionEntidadeHsCom';
import { changeEntidadeInitialState } from '../actions/actions'
import ReactTooltip from "react-tooltip";

const ROLE_SYSTEM = "System";
const ROLE_ADM = "Admin";
const ROLE_entidades = "entidades";

class TabelaEntidade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entidades: [],
            entidadesFiltradas: [],
            pesquisa: '',
            tipoPesquisa: '',
            tipoSituacao: '',
            size: 7,
            page: 1,
            currPage: null,
            tipoErro: [],
            currentRole: '',
            nomeEntidade: '',
            emailEntidade: '',
            quantEmpresas: '',
            quantFuncionarios: '',
            quantUsuarios: '',
            situacao: '',
            CNPJ: '',
            inicioContrato: '',
            IDEntidade: '',
            recuperaUsuario: false,
            idEntidadeAcesso: '',
            redirect: false,
            erro: false,
            exclusao: false,
            loading: false,
            refresh: false,
            entidadeSelecionada: null
        };

        this.requisicaoListagem = this.requisicaoListagem.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.requisicaoAtualizacao = this.requisicaoAtualizacao.bind(this);
        this.mostraAtualizacao = this.mostraAtualizacao.bind(this);
        this.atribuiTipoPesquisa = this.atribuiTipoPesquisa.bind(this);
        this.atribuiPesquisa = this.atribuiPesquisa.bind(this);
        this.limpaPesquisa = this.limpaPesquisa.bind(this);
        this.filtro = this.filtro.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.buttonPaginacao = this.buttonPaginacao.bind(this);
        this.atribuiTipoSituacao = this.atribuiTipoSituacao.bind(this);
        this.requisicaoConsulta = this.requisicaoConsulta.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.verificaRole = this.verificaRole.bind(this);

        this.formataDataResponse = this.formataDataResponse.bind(this);
        this.formataDataExibicao = this.formataDataExibicao.bind(this);
        this.requisicaoDownloadBd = this.requisicaoDownloadBd.bind(this);
    }

    formataDataResponse(data) {
        if(!data)
            return;

        let dataFormatada = data.split("T");
        return dataFormatada[0];
    }

    formataDataExibicao(data) {
        if(!data)
            return '';

        let dataConvertida = new Date(data);
        return `${("0" + dataConvertida.getDate()).slice(-2)}/${("0" + parseInt(dataConvertida.getMonth() + 1)).slice(-2)}/${dataConvertida.getFullYear()}`;
    }

    requisicaoConsulta(IDEntidade) {
        this.setState({ loading: true })
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'entidades/' + IDEntidade,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                this.setState({
                    entidadeSelecionada: res.data,
                    loading: false,
                    redirect: true
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalErro();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    requisicaoDownloadBd(IDEntidade) {
        this.setState({ loading: true })
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'entidades/url-download-licenca/' + IDEntidade,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                window.open(res.data)
                this.setState({
                    loading: false,
                    redirect: false
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalErro();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    verificaRole() {
        switch (this.props.currentRole) {
            case ROLE_SYSTEM:
                return true;
            case ROLE_ADM:
                return true;
            case ROLE_entidades:
                return false;
            default:
                return false
        }
    }

    componentDidMount() {
        // Faz com que volte ao inicio da página
        window.scrollTo(0, 0);

        // Inicia a listagem  de entidades cadastradas
        this.requisicaoListagem();
    }

    requisicaoListagem() {
        this.setState({ loading: true });
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'entidades',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const entidades = res.data;
                let { page, size } = this.state;
                const currPage = paginate(entidades, page, size);

                this.setState({
                    entidades: res.data,
                    loading: false,
                    currPage,
                    entidadesFiltradas: entidades,
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                } 
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.requisicaoListagem();
                }

            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    requisicaoAtualizacao() {
        let entidades = this.state.entidades;
        let IDEntidade = this.state.IDEntidade;

        let posicao = entidades.findIndex(entidade => entidade.IDEntidade === IDEntidade);
        entidades.splice(posicao, 1);

        let { page, size } = this.state;

        let currPage = paginate(entidades, page, size);

        if (currPage.totalPages < page) {
            page = currPage.totalPages;
            currPage = paginate(entidades, page, size);
        }

        this.setState({
            entidades,
            page,
            currPage,
            entidadesFiltradas: entidades
        });
    }

    atribuiTipoPesquisa(event) {
        // Atribui o tipo de pesquisa que será usada no filtro
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa, pesquisa: '' });
    }

    atribuiTipoSituacao(event) {
        this.setState({ tipoSituacao: event.target.value });
    }

    atribuiPesquisa(event) {
        // Atribui a pesquisa que será usada no filtro
        let pesquisa = event.target.value;
        this.setState({ pesquisa });
    }

    limpaPesquisa() {
        this.setState({ pesquisa: '' });
    }

    filtro(event) {
        //Faz a filtragem em sí
        event.preventDefault();
        let { tipoPesquisa } = this.state;
        let { pesquisa } = this.state;
        let entidadesFiltradas = [];

        if (tipoPesquisa === "nome") {
            if (pesquisa !== '') {
                entidadesFiltradas = this.state.entidades.filter(entidades => {
                    return entidades.Nome.toLowerCase().includes(pesquisa.toLowerCase());
                });
            }
            else {
                entidadesFiltradas = this.state.entidades;
            }
        }
        else if (tipoPesquisa === "situacao") {
            entidadesFiltradas = this.state.entidades.filter(entidades => {
                return entidades.Situacao === parseInt(this.state.tipoSituacao);
            });
        }
        else {
            entidadesFiltradas = this.state.entidades;
        }

        this.setState({ entidadesFiltradas });
        this.mostraAtualizacao(entidadesFiltradas);
    }

    mostraAtualizacao(entidadesFiltradas) {
        //Atualiza as entidades que aparecem após a filtragem
        const size = this.state.size;

        const currPage = paginate(entidadesFiltradas, 1, size);

        this.setState({ currPage, page: 1 });
    }

    firstPage() {
        //Determina a próxima página
        const { size, entidadesFiltradas } = this.state;

        const newPage = 1;
        const newCurrPage = paginate(entidadesFiltradas, newPage, size);

        this.setState({
            page: newPage,
            currPage: newCurrPage
        });
    }

    lastPage() {
        //Determina a página anterior
        const { size, entidadesFiltradas } = this.state;

        if (this.state.currPage !== null) {
            const newPage = this.state.currPage.totalPages > 0 ? this.state.currPage.totalPages : 1;
            const newCurrPage = paginate(entidadesFiltradas, newPage, size);
            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    previousPage() {
        const { page, size, entidadesFiltradas } = this.state;

        if (page > 1) {
            const newPage = page - 1;
            const newCurrPage = paginate(entidadesFiltradas, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    nextPage() {
        const { currPage, page, size, entidadesFiltradas } = this.state;

        if (this.state.currPage !== null) {
            if (page < currPage.totalPages) {
                let newPage = page + 1;
                let newCurrPage = paginate(entidadesFiltradas, newPage, size);
                this.setState({
                    page: newPage,
                    currPage: newCurrPage
                });
            }
        }
    }

    buttonPaginacao(currPage) {
        if (currPage !== null) {
            if (currPage.totalPages === 1 || currPage.totalPages === 0) {
                return (
                    <button className="btn btn-outline-primary active">{this.state.page}</button>
                );
            }
            else if (currPage.currentPage === currPage.totalPages) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                    </div>
                );
            }
            else if (this.state.page - 1 === 0) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
            else {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
        }
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro });
    }

    render() {
        this.props.changeEntidadeInitialState();

        if (this.state.redirect === true) {
            this.props.changeNomeEntidade(this.state.entidadeSelecionada.Nome);
            this.props.changeEmailEntidade(this.state.entidadeSelecionada.Email);
            this.props.changeCnpjEntidade(this.state.entidadeSelecionada.Cnpj);
            this.props.changeInicioContratoEntidade(this.formataDataResponse(this.state.entidadeSelecionada.InicioContrato));
            this.props.changeRecuperaUsuarioEntidade(this.state.entidadeSelecionada.recuperaUsuario);
            this.props.changeIdEntidade(this.state.entidadeSelecionada.IDEntidade);
            this.props.changeIdAcessoEntidade(this.state.entidadeSelecionada.IDEntidadeAcesso);

            this.props.changeSituacaoHsMobile(this.state.entidadeSelecionada.HsMobile?.Situacao);
            this.props.changeQuantidadeEmpresaHsMobile(this.state.entidadeSelecionada.HsMobile?.QuantidadeEmpresas);
            this.props.changeQuantidadeFuncionarioHsMobile(this.state.entidadeSelecionada.HsMobile?.QuantidadeFuncionarios);
            this.props.changeQuantidadeUsuarioHsMobile(this.state.entidadeSelecionada.HsMobile?.QuantidadeUsuarios);
            this.props.changeDataFimLicencaHsMobile(this.formataDataResponse(this.state.entidadeSelecionada.HsMobile?.DataFimLicenca))

            this.props.changeSituacaoHsPonto(this.state.entidadeSelecionada.HsPonto?.Situacao);
            this.props.changeQuantidadeEmpresaHsPonto(this.state.entidadeSelecionada.HsPonto?.QuantidadeEmpresas);
            this.props.changeQuantidadeFuncionarioHsPonto(this.state.entidadeSelecionada.HsPonto?.QuantidadeFuncionarios);
            this.props.changeQuantidadeUsuarioHsPonto(this.state.entidadeSelecionada.HsPonto?.QuantidadeUsuarios);
            this.props.changeQuantidadeEquipamentoHsPonto(this.state.entidadeSelecionada.HsPonto?.QuantidadeEquipamentos);
            this.props.changeDataFimLicencaHsPonto(this.formataDataResponse(this.state.entidadeSelecionada.HsPonto?.DataFimLicenca))

            this.props.changeSituacaoHsCom(this.state.entidadeSelecionada.HsCom?.Situacao);
            this.props.changeQuantidadeEmpresaHsCom(this.state.entidadeSelecionada.HsCom?.QuantidadeEmpresas);
            this.props.changeQuantidadeFuncionarioHsCom(this.state.entidadeSelecionada.HsCom?.QuantidadeFuncionarios);
            this.props.changeQuantidadeUsuarioHsCom(this.state.entidadeSelecionada.HsCom?.QuantidadeUsuarios);
            this.props.changeQuantidadeEquipamentoHsCom(this.state.entidadeSelecionada.HsCom?.QuantidadeEquipamentos);
            this.props.changeDataFimLicencaHsCom(this.formataDataResponse(this.state.entidadeSelecionada.HsCom?.DataFimLicenca))

            return <Redirect to={{
                pathname: '/Home/Entidade/NovaEntidade'
            }} />
        }

        const { currPage } = this.state;

        let pesquisa = this.state.tipoPesquisa === "situacao" ?
            <select
                className="form-control col-10"
                type="select"
                name="tipoSituacao"
                required
                onChange={this.atribuiTipoSituacao}
                value={this.state.tipoSituacao}>
                <option defaultValue> Selecionar </option>
                <option value={1} className="input-group-text"> Liberado </option>
                <option value={0} className="input-group-text"> Bloqueado </option>
            </select>
            :
            <input id="filtro"
                placeholder="Pesquisar"
                type="text"
                value={this.state.pesquisa}
                className="form-control col-10"
                onChange={this.atribuiPesquisa}
                autoComplete="off" />;
        return (
            <div>
                <ReactTooltip place="left" type="success" effect="solid" />
                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center ModalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center ModalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Conectando... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <nav>
                    <div className="row filtro tamanhoRow">
                        <div className="col">
                            <span className="navbar-brand"><label> Entidade </label></span>
                        </div>
                        {this.verificaRole() ?
                            <div className="row filtro">
                                <div className="col tiraPadding">
                                    <div className="input-group-prepend"><form>
                                        <div className="row">
                                            <div className="col-4 col-sm-5 col-md-4 col-lg-4">
                                                <select
                                                    className="form-control input-group-prepend"
                                                    type="select"
                                                    name="tipopesquisa"
                                                    required
                                                    onChange={this.atribuiTipoPesquisa}
                                                    value={this.state.tipoPesquisa}>
                                                    <option defaultValue> Selecione </option>
                                                    <option value="nome" className="input-group-text"> Nome </option>
                                                    <option value="situacao" className="input-group-text"> Situação </option>
                                                </select>
                                            </div>
                                            <div className="col-8 col-sm-7 col-md-8 col-lg-8 tiraPadding">
                                                <div className="input-group-prepend">
                                                    <div className="input-group">
                                                        {pesquisa}
                                                        <button type="submit" className="input-group-text" onClick={this.filtro}><FaSistrix /></button>
                                                    </div>
                                                    <div className="col text-right tiraPadding">
                                                        <NavbarEntidade />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>
                            :
                            null}
                    </div>
                </nav>
                <div className="table-responsive">
                    <table className="table table-bordeless table-sm text-center">
                        <thead>
                            <tr className="table table-primary">
                                <th scope="col" colSpan="1"> ID Acesso </th>
                                <th scope="col" colSpan="1"> CNPJ </th>
                                <th scope="col" colSpan="1"> Nome </th>
                                <th scope="col" colSpan="1"> HSMobile </th>
                                <th scope="col" colSpan="1"> HSPonto </th>
                                <th scope="col" colSpan="1"> HSCom </th>
                                <th scope="col" colSpan="2">  </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currPage &&
                                currPage.data.map(entidades =>
                                    <tr key={entidades.IDEntidade}>
                                        <td className="align-middle" colSpan="1">{entidades.IDEntidadeAcesso}</td>
                                        <td className="align-middle" colSpan="1">{entidades.Cnpj}</td>
                                        <td className="align-left" colSpan="1" title={entidades.Nome.length > 60 ? entidades.Nome : ''}>
                                            {entidades.Nome.length > 60 ? entidades.Nome.substring(0, 59) + '...' : entidades.Nome}</td>
                                        <td className="align-middle" colSpan="1" 
                                            title={entidades.HsMobile ? 
                                            `Quantidade Empresas: ${entidades.HsMobile.QuantidadeEmpresas}
Quantidade Funcionários: ${entidades.HsMobile.QuantidadeFuncionarios}
Quantidade Usuários: ${entidades.HsMobile.QuantidadeUsuarios}
Data licença: ${this.formataDataExibicao(entidades.HsMobile.DataFimLicenca)}` : ''}>
                                            {entidades.HsMobile ? entidades.HsMobile.Situacao === 0 ? 
                                            `Bloqueado` : `Qtde Func: ${entidades.HsMobile.QuantidadeFuncionarios}` : "-"}
                                        </td>
                                        <td className="align-middle" colSpan="1"
                                        title={entidades.HsPonto ? 
                                            `Quantidade Empresas: ${entidades.HsPonto.QuantidadeEmpresas}
Quantidade Funcionários: ${entidades.HsPonto.QuantidadeFuncionarios}
Quantidade Usuários: ${entidades.HsPonto.QuantidadeUsuarios}
Quantidade Equipamentos: ${entidades.HsPonto.QuantidadeEquipamentos}
Data licença: ${this.formataDataExibicao(entidades.HsPonto.DataFimLicenca)}` : ''}>
                                            {entidades.HsPonto ? entidades.HsPonto.Situacao === 0 ? 
                                            `Bloqueado` : `Qtde Func: ${entidades.HsPonto.QuantidadeFuncionarios}` : "-"}
                                        </td>
                                        <td className="align-middle" colSpan="1"
                                            title={entidades.HsCom ? 
                                                `Quantidade Empresas: ${entidades.HsCom.QuantidadeEmpresas}
Quantidade Funcionários: ${entidades.HsCom.QuantidadeFuncionarios}
Quantidade Usuários: ${entidades.HsCom.QuantidadeUsuarios}
Quantidade Equipamentos: ${entidades.HsCom.QuantidadeEquipamentos}
Data licença: ${this.formataDataExibicao(entidades.HsCom.DataFimLicenca)}` : ''}>
                                            {entidades.HsCom ? entidades.HsCom.Situacao === 0 ? 
                                            `Bloqueado` : `Qtde Equip: ${entidades.HsCom.QuantidadeEquipamentos}` : "-"}
                                        </td>
                                        <td className="align-right" colSpan="1">
                                            <button className="btn" data-tip="Editar" onClick={() => this.requisicaoConsulta(entidades.IDEntidade)}><GoPencil size={20} /></button>
                                        </td>
                                        <td className="align-right" colSpan="1">
                                            <button className="btn" data-tip="Download" disabled={entidades.HsPonto ? false : true}  onClick={() => this.requisicaoDownloadBd(entidades.IDEntidade)}><FaDownload size={20} /></button>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-end right paginacao">
                    <div className="btn-toolbar">
                        <div className="btn-group">
                            <button className="btn btn-outline-primary" onClick={this.firstPage}><MdFirstPage /></button>
                            <button className="btn btn-outline-primary" onClick={this.previousPage}><MdNavigateBefore /></button>

                            {this.buttonPaginacao(currPage)}

                            <button className="btn btn-outline-primary" onClick={this.nextPage}><MdNavigateNext /></button>
                            <button className="btn btn-outline-primary" onClick={this.lastPage}><MdLastPage /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    currentRole: state.dadosRole.currentRole
});

const mapActionstoProps = {
    changeNomeEntidade,
    changeEmailEntidade,
    changeCnpjEntidade,
    changeInicioContratoEntidade,
    changeRecuperaUsuarioEntidade,
    changeIdEntidade,
    changeIdAcessoEntidade,
    
    changeQuantidadeEmpresaHsMobile,
    changeQuantidadeFuncionarioHsMobile,
    changeQuantidadeUsuarioHsMobile,
    changeSituacaoHsMobile,
    changeDataFimLicencaHsMobile,

    changeQuantidadeEmpresaHsPonto,
    changeQuantidadeFuncionarioHsPonto,
    changeQuantidadeUsuarioHsPonto,
    changeQuantidadeEquipamentoHsPonto,
    changeSituacaoHsPonto,
    changeDataFimLicencaHsPonto,

    changeQuantidadeEmpresaHsCom,
    changeQuantidadeFuncionarioHsCom,
    changeQuantidadeUsuarioHsCom,
    changeQuantidadeEquipamentoHsCom,
    changeSituacaoHsCom,
    changeDataFimLicencaHsCom,

    changeEntidadeInitialState
}

export default connect(mapStateToProp, mapActionstoProps)(TabelaEntidade);
import React, { Component } from 'react';
import NavbarUsuario from './botao_paraForm';
import Modal from 'react-modal';
import { GoPencil, GoTrashcan } from "react-icons/go";
import { Link, Redirect } from "react-router-dom"
import axios from 'axios';
import Cookies from 'universal-cookie';
import paginate from 'paginate-array';
import { Spinner } from 'reactstrap';
import { FaSistrix } from 'react-icons/fa';
import { MdNavigateBefore, MdLastPage, MdNavigateNext, MdFirstPage } from 'react-icons/md';

export default class TabelaUsuario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usuarios: [],
            usuariosFiltrados: [],
            pesquisa: '',
            tipoPesquisa: '',
            size: 7,
            page: 1,
            currPage: null,
            tipoErro: [],
            role: { value: '', label: '' },
            userUsuario: '',
            cpfUsuario: '',
            alterasenha: '',
            IDUsuario: '',
            redirect: false,
            erro: false,
            exclusao: false,
            loading: false,
            refresh: false
        };

        this.requisicaoConsulta = this.requisicaoConsulta.bind(this);
        this.requisicaoListagem = this.requisicaoListagem.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);

        this.requisicaoAtualizacao = this.requisicaoAtualizacao.bind(this);
        this.mostraAtualizacao = this.mostraAtualizacao.bind(this);
        this.requisicaoDelete = this.requisicaoDelete.bind(this);
        this.atribuiTipoPesquisa = this.atribuiTipoPesquisa.bind(this);
        this.atribuiPesquisa = this.atribuiPesquisa.bind(this);
        this.limpaPesquisa = this.limpaPesquisa.bind(this);
        this.filtro = this.filtro.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.buttonPaginacao = this.buttonPaginacao.bind(this);

        this.ModalDelete = this.ModalDelete.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
    }

    componentDidMount() {
        // Faz com que volte ao inicio da página
        window.scrollTo(0, 0);

        // Inicia a listagem  de usuarios cadastrados
        this.requisicaoListagem();
    }

    requisicaoListagem() {
        this.setState({ loading: true });
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'usuarios',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const usuarios = res.data;
                let { page, size } = this.state;

                const currPage = paginate(usuarios, page, size);

                this.setState({
                    usuarios: res.data,
                    loading: false,
                    currPage,
                    usuariosFiltrados: usuarios,
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalErro();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    requisicaoConsulta(IDUser) {
        this.setState({ loading: true });
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'usuarios/' + IDUser,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                this.setState({
                    role: { value: res.data.Role, label: res.data.Role },
                    userUsuario: res.data.UserName,
                    cpfUsuario: res.data.CPF,
                    alterasenha: res.data.AlteraSenha,
                    IDUsuario: res.data.IDUser,
                    redirect: true,
                    loading: false
                })
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalErro();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.requisicaoListagem();
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    requisicaoDelete() {
        this.setState({ loading: true });

        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.delete(process.env.REACT_APP_URL_REQUISICOES + 'usuarios/' + this.state.IDUsuario,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                this.setState({ exclusao: !this.state.exclusao, loading: false });
                this.requisicaoAtualizacao();
            })
            .catch((error) => {
                if (error.response !== undefined && error.response === '') {
                    if (error.response.status === 401) {
                        this.requisicaoRefresh();
                    }
                    else {
                        this.setState({
                            tipoErro: error.response.data,
                            loading: false,
                            exclusao: !this.state.exclusao
                        });
                        this.ModalErro();
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identifcado. Contate o administrador",
                        loading: false,
                        exclusao: !this.state.exclusao
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoAtualizacao() {
        let usuarios = this.state.usuarios;
        let IDUsuario = this.state.IDUsuario;

        let posicao = usuarios.findIndex(usuario => usuario.IDUser === IDUsuario);
        usuarios.splice(posicao, 1);

        let { page, size } = this.state;

        let currPage = paginate(usuarios, page, size);

        if (currPage.totalPages < page) {
            page = currPage.totalPages;
            currPage = paginate(usuarios, page, size);
        }

        this.setState({
            usuarios,
            page,
            currPage,
            usuariosFiltrados: usuarios
        });
    }

    atribuiTipoPesquisa(event) {
        // Atribui o tipo de pesquisa que será usada no filtro
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa, pesquisa: '' });
    }

    atribuiPesquisa(event) {
        // Atribui a pesquisa que será usada no filtro
        let pesquisa = event.target.value;
        this.setState({ pesquisa });
    }

    limpaPesquisa() {
        this.setState({ pesquisa: '' });
    }

    filtro(event) {
        //Faz a filtragem em sí
        event.preventDefault();
        let { tipoPesquisa } = this.state;
        let { pesquisa } = this.state;
        let usuariosFiltrados = [];

        if (tipoPesquisa === "username") {
            if (pesquisa !== '') {
                usuariosFiltrados = this.state.usuarios.filter(usuarios => {
                    return usuarios.UserName.toLowerCase().includes(pesquisa.toLowerCase());
                });
            }
            else {
                usuariosFiltrados = this.state.usuarios;
            }
        }
        else if (tipoPesquisa === "role") {
            usuariosFiltrados = this.state.usuarios.filter(usuarios => {
                return usuarios.Role.toLowerCase().includes(pesquisa.toLowerCase());
            });
        }
        else {
            usuariosFiltrados = this.state.usuarios;
        }

        this.setState({ usuariosFiltrados });
        this.mostraAtualizacao(usuariosFiltrados);
    }

    mostraAtualizacao(usuariosFiltrados) {
        //Atualiza os usuarios que aparecem após a filtragem
        const size = this.state.size;

        const currPage = paginate(usuariosFiltrados, 1, size);

        this.setState({ currPage, page: 1 });
    }

    firstPage() {
        //Determina a próxima página
        const { size, usuariosFiltrados } = this.state;

        const newPage = 1;
        const newCurrPage = paginate(usuariosFiltrados, newPage, size);

        this.setState({
            page: newPage,
            currPage: newCurrPage
        });
    }

    lastPage() {
        //Determina a página anterior
        const { size, usuariosFiltrados } = this.state;

        if (this.state.currPage !== null) {
            const newPage = this.state.currPage.totalPages > 0 ? this.state.currPage.totalPages : 1;
            const newCurrPage = paginate(usuariosFiltrados, newPage, size);
            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    previousPage() {
        const { page, size, usuariosFiltrados } = this.state;

        if (page > 1) {
            const newPage = page - 1;
            const newCurrPage = paginate(usuariosFiltrados, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    nextPage() {
        const { currPage, page, size, usuariosFiltrados } = this.state;

        if (this.state.currPage !== null) {
            if (page < currPage.totalPages) {
                let newPage = page + 1;
                let newCurrPage = paginate(usuariosFiltrados, newPage, size);
                this.setState({
                    page: newPage,
                    currPage: newCurrPage
                });
            }
        }
    }

    buttonPaginacao(currPage) {
        if (currPage !== null) {
            if (currPage.totalPages === 1 || currPage.totalPages === 0) {
                return (
                    <button className="btn btn-outline-primary active">{this.state.page}</button>
                );
            }
            else if (currPage.currentPage === currPage.totalPages) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                    </div>
                );
            }
            else if (this.state.page - 1 === 0) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
            else {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
        }
    }

    ModalDelete(IDUsuario) {
        this.setState({ IDUsuario, exclusao: !this.state.exclusao });
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro });
    }

    render() {
        let currPage  = this.state.currPage;

        if (this.state.redirect === true) {
            return <Redirect to={{
                pathname: '/Home/Usuario/NovoUsuario',
                state: {
                    role: this.state.role,
                    userUsuario: this.state.userUsuario,
                    cpfUsuario: this.state.cpfUsuario,
                    alterasenha: this.state.alterasenha,
                    IDUsuario: this.state.IDUsuario
                }
            }} />
        }
        return (
            <div>
                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center ModalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal //Modal de exclusão
                    isOpen={this.state.exclusao}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Certeza que deseja excluir esses dados? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.requisicaoDelete} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalDelete} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center ModalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Conectando... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <nav>
                    <div className="row filtro tamanhoRow">
                        <div className="col">
                            <span className="navbar-brand"><label> Usuário </label></span>
                        </div>
                        <div className="row filtro">
                            <div className="col tiraPadding">
                                <div className="input-group-prepend"><form>
                                    <div className="row">
                                        <div className="col-4 col-sm-5 col-md-4 col-lg-4">
                                            <select
                                                className="form-control input-group-prepend"
                                                type="select"
                                                name="tipopesquisa"
                                                required
                                                onChange={this.atribuiTipoPesquisa}
                                                value={this.state.tipoPesquisa}>
                                                <option defaultValue> Selecione </option>
                                                <option value="username" className="input-group-text"> Username </option>
                                                <option value="role" className="input-group-text"> Role </option>
                                            </select>
                                        </div>
                                        <div className="col-8 col-sm-7 col-md-8 col-lg-8 tiraPadding">
                                            <div className="input-group-prepend">
                                                <div className="input-group">
                                                    <input id="filtro"
                                                        placeholder="Pesquisar"
                                                        type="text"
                                                        value={this.state.pesquisa}
                                                        className="form-control col-10"
                                                        onChange={this.atribuiPesquisa}
                                                        autoComplete="off" />
                                                    <button type="submit" className="input-group-text" onClick={this.filtro}><FaSistrix /></button>
                                                </div>
                                                <div className="col text-right tiraPadding">
                                                    <NavbarUsuario />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="table-responsive">
                    <table className="table table-hover table-bordeless table-sm text-center">
                        <thead>
                            <tr className="table table-primary">
                                <th scope="col"> Nome </th>
                                <th scope="col"> Permissão </th>
                                <th scope="col" colSpan="2">  </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currPage &&
                                currPage.data.map(usuarios =>
                                    <tr key={usuarios.IDUser}>
                                        <td className="align-middle">{usuarios.UserName}</td>
                                        <td className="align-middle">{usuarios.Role}</td>
                                        <td className="align-middle">
                                            <button className="btn" data-tip="Editar" onClick={() => this.requisicaoConsulta(usuarios.IDUser)}><GoPencil size={20} /></button>
                                        </td>
                                        <td className="align-middle">
                                            <button className="btn" data-tip="Excluir" onClick={() => this.ModalDelete(usuarios.IDUser)}><GoTrashcan size={20} /></button>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>

                <div className="row justify-content-end right paginacao">
                    <div className="btn-toolbar">
                        <div className="btn-group">
                            <button className="btn btn-outline-primary" onClick={this.firstPage}><MdFirstPage /></button>
                            <button className="btn btn-outline-primary" onClick={this.previousPage}><MdNavigateBefore /></button>

                            {this.buttonPaginacao(currPage)}

                            <button className="btn btn-outline-primary" onClick={this.nextPage}><MdNavigateNext /></button>
                            <button className="btn btn-outline-primary" onClick={this.lastPage}><MdLastPage /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FormUsuario from './formUsuario';

const RotasUsuario = () => (
    <Switch>
        <Route exact path='/Home/Usuario/NovoUsuario' component={FormUsuario} />
    </Switch>
);

export default RotasUsuario;
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import { FaBuilding, FaSignInAlt, FaUserAlt } from "react-icons/fa";
import { connect } from 'react-redux';

import MainEntidade from '../entidade/mainEntidade';
import MainUsuario from '../usuario/mainUsuario';
import { changeInitialState, changeLogout } from '../actions/actions';

const ROLE_SYSTEM = "System";
const ROLE_ADM = "Admin";
const ROLE_USER = "User";

Modal.setAppElement('#root');

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            cancela: false,
            redirect: false
        }
        this.setIsOpen = this.setIsOpen.bind(this);
        this.fechaToggle = this.fechaToggle.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.sair = this.sair.bind(this);
    }

    verificaRole() {
        switch (this.props.currentRole) {
            case ROLE_SYSTEM:
                return true;
            case ROLE_ADM:
                return false;
            case ROLE_USER:
                return false;

            default:
                return false
        }
    }

    setIsOpen() {
        let isOpen = this.state.isOpen
        this.setState({ isOpen: !isOpen })
    }

    fechaToggle() {
        this.setState({ isOpen: false })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela });
    }

    sair() {
        this.props.changeInitialState();

        if(this.props.conectado === false){
            this.props.changeLogout();
        }

        this.setState({ redirect: true })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.sair} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Navbar color="primary" dark expand="md" onBlur={this.fechaToggle}>
                    <NavbarToggler onClick={this.setIsOpen} />
                    <NavbarBrand className="mr-auto text-light">
                        HS Admin.
                    </NavbarBrand>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mx-auto" navbar>
                            <NavItem className="margemNavlink">
                                <Link to="/Home/Entidade" className="text-light"><FaBuilding /> Entidade </Link>
                            </NavItem>
                            {this.verificaRole() ?
                                <NavItem className="margemNavlink">
                                    <Link to="/Home/Usuario" className="text-light"><FaUserAlt /> Usuário </Link>
                                </NavItem>
                                :
                                null}
                        </Nav>
                    </Collapse>
                    <NavItem className="tiraMarcador">
                        <button onClick={this.ModalCancelamento} className="btn btn-link text-light"><FaSignInAlt size={20} /></button>
                    </NavItem>
                </Navbar>

                <div className="divConteudo">
                    <Route path="/Home/Entidade" component={MainEntidade} />
                    {this.verificaRole() ?
                        <Route path="/Home/Usuario" component={MainUsuario} />
                        :
                        null}
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    currentRole: state.dadosRole.currentRole,
    conectado: state.dadosRole.conectado
});

const mapActionstoProps = {
    changeInitialState,
    changeLogout
}

export default connect(mapStateToProp, mapActionstoProps)(Home);
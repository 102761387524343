import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FaPlus } from "react-icons/fa";

export default class NavbarUsuario extends Component {

    render() {
        return (
            <Link
                to={{
                    pathname: '/Home/Usuario/NovoUsuario',
                    state: {
                        role: { value: '', label: '' },
                        userUsuario: '',
                        cpfUsuario: '',
                        alterasenha: true,
                        IDUsuario: ''
                    }
                }}>
                <button className="btn btn-outline-primary botaoParaFormulario">
                    <FaPlus size={20} className="icone" />
                </button>
            </Link>
        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Select from 'react-select';
import CheckBox from 'react-simple-checkbox';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Spinner } from 'reactstrap';

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT'
});

const options = [
    { value: 'System', label: 'System' },
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' }
];

Modal.setAppElement('#root');

export default class FormUsuario extends Component {
    constructor(state) {
        super(state);

        this.state = {
            role: this.props.location.state.role,
            userUsuario: this.props.location.state.userUsuario,
            cpfUsuario: this.props.location.state.cpfUsuario,
            cpfUsuario_erro: '',
            alterasenha: this.props.location.state.alterasenha,

            validador: false,
            IDUsuario: this.props.location.state.IDUsuario,
            tipoErro: [],

            erro: false,
            cancela: false,
            sucesso: false,
            refresh: false,
            loading: false
        }

        this.atribuiRole = this.atribuiRole.bind(this);

        this.atribuiUser = this.atribuiUser.bind(this);

        this.atribuiAlteraSenha = this.atribuiAlteraSenha.bind(this);

        this.atribuiCPF = this.atribuiCPF.bind(this);
        this.validaCpf = this.validaCpf.bind(this);
        this.mascaraCPF = this.mascaraCPF.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.enviaDados = this.enviaDados.bind(this);
        this.requisicaoEnvio = this.requisicaoEnvio.bind(this);
        this.requisicaoAlteracao = this.requisicaoAlteracao.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
    }

    atribuiAlteraSenha(check) {
        this.setState({ alterasenha: check })
    }

    atribuiRole(role) {
        this.setState({ role })
    }

    atribuiUser(e) {
        this.setState({ userUsuario: e.target.value })
    }

    atribuiCPF(e) {
        let cpfUsuario = e.target.value;

        this.setState({ cpfUsuario })
    }

    validaCpf() {
        let cpfUsuario = this.state.cpfUsuario;
        cpfUsuario = cpfUsuario.replace(/[^\d]+/g, '');
        var validador = true;

        if (cpfUsuario === '') {
            validador = false;
        }

        else if (cpfUsuario.length !== 11 ||
            cpfUsuario === '00000000000' ||
            cpfUsuario === '11111111111' ||
            cpfUsuario === '22222222222' ||
            cpfUsuario === '33333333333' ||
            cpfUsuario === '44444444444' ||
            cpfUsuario === '55555555555' ||
            cpfUsuario === '66666666666' ||
            cpfUsuario === '77777777777' ||
            cpfUsuario === '88888888888' ||
            cpfUsuario === '99999999999') {
            validador = false;
        }

        else {
            var add = 0;
            for (var j = 0; j < 9; j++) {
                add += parseInt(cpfUsuario.charAt(j)) * (10 - j);
            }
            var rev = (add % 11);

            if (rev < 2)
                rev = 0;
            else
                rev = 11 - rev;

            if (rev !== parseInt(cpfUsuario.charAt(9))) {
                validador = false;
            }

            add = 0;
            for (var i = 0; i < 10; i++) {
                add += parseInt(cpfUsuario.charAt(i)) * (11 - i);
                rev = 11 - (add % 11);
            }

            if (rev === 10 || rev === 11) {
                rev = 0;
            }

            else if (rev !== parseInt(cpfUsuario.charAt(10))) {
                validador = false;
            }
        }

        let cpfUsuario_erro = validador ? null : 'CPF Inválido'
        validador = validador && true;//&& = and
        this.setState({ cpfUsuario_erro })

        this.mascaraCPF(cpfUsuario);
    }

    mascaraCPF(cpfUsuario) {
        let { validador } = this.state;

        if (cpfUsuario.length === 11) {
            let cpfAux1 = '';
            let cpfAux2 = '';
            let cpfAux3 = '';
            let cpfAux4 = '';

            cpfAux1 += cpfUsuario.substring(0, 3) + '.';
            cpfAux2 += cpfUsuario.substring(3, 6) + '.';
            cpfAux3 += cpfUsuario.substring(6, 9) + '-';
            cpfAux4 += cpfUsuario.substring(9, 11);

            let cpfPontuacao = cpfAux1 + cpfAux2 + cpfAux3 + cpfAux4;

            this.setState({ cpfUsuario: cpfPontuacao });
        }
        else {
            let cpfUsuario_erro = validador ? null : 'CPF Inválido'
            this.setState({ cpfUsuario_erro })
        }
    }

    enviaDados(event) {
        event.preventDefault();
        this.setState({ loading: true });

        if (this.state.IDUsuario === '') {
            this.requisicaoEnvio();
        }
        else {
            this.requisicaoAlteracao();
        }
    }

    requisicaoEnvio() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'usuarios',
            {
                Role: this.state.role.value,
                CPF: this.state.cpfUsuario,
                Senha: this.state.senhaUsuario,
                UserName: this.state.userUsuario,
                AlteraSenha: this.state.alterasenha
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        mensagemSucesso: 'Cadastro feito com sucesso!',
                        loading: false
                    });
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.POST);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            });
    }

    requisicaoAlteracao() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.put(process.env.REACT_APP_URL_REQUISICOES + 'usuarios/' + this.state.IDUsuario,
            {
                IDUsuario: this.state.IDUsuario,
                Role: this.state.role.value,
                CPF: this.state.cpfUsuario,
                Senha: this.state.senhaUsuario,
                UserName: this.state.userUsuario,
                AlteraSenha: this.state.alterasenha
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        mensagemSucesso: 'Alteração feita com sucesso!',
                        loading: false
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.PUT);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            // console.log("Requisição não identificada");
                            break;
                    }

                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso });
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    render() {
        return (
            <div>
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/Usuario'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/Usuario">
                                    <button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Enviando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <form onSubmit={this.enviaDados}>
                    <div className="form-row margemFormulario">
                        <div className="form-group col-12 col-sm-6">
                            <label> Usuário: </label>
                            <input
                                type="text"
                                id="userUsuario"
                                name="userUsuario"
                                value={this.state.userUsuario}
                                onChange={this.atribuiUser}
                                required
                                className='form-control'
                                autoComplete="off" />
                            <div className='invalid-feedback'></div>
                        </div>
                        <div className="form-group col-12 col-sm-6">
                            <label> CPF: </label>
                            <input
                                type="text"
                                id="cpfUsuario"
                                name="cpfUsuario"
                                maxLength='14'
                                value={this.state.cpfUsuario}
                                onChange={this.atribuiCPF}
                                className={this.state.cpfUsuario_erro ? 'form-control is-invalid' : 'form-control'}
                                onBlur={this.state.cpf !== '' ? this.validaCpf : null}
                                required='required'
                                autoComplete='off'
                            />
                            <div className='invalid-feedback'>{this.state.cpfUsuario_erro}</div>
                        </div>
                    </div>

                    <div className="form-row margemFormulario">
                        <div className="form-group col-12 col-sm-6">
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label> Role: </label>
                                    <Select
                                        value={this.state.role} //valor atual
                                        onChange={this.atribuiRole}
                                        options={options} //opções do select
                                    />
                                </div>
                                <div className="form-group col-6 text-center">
                                    <label htmlFor="alteraSenha">Primeiro acesso:</label>
                                    <div>
                                        <CheckBox
                                            id='alteraSenha'
                                            size={4}
                                            tickSize={3}
                                            borderThickness={1}
                                            checked={this.state.alterasenha}
                                            onChange={this.atribuiAlteraSenha}
                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                            tickAnimationDuration={100}
                                            backAnimationDuration={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-6 text-right">
                            <button
                                type="submit"
                                className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                Salvar
                            </button>
                        </div>
                        <div className="form-group col-6 text-left">
                            <button
                                type="button"
                                onClick={this.ModalCancelamento}
                                className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
import React, { Component } from "react";
import {NavLink} from 'react-router-dom';

export default class NavbarUsuario extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-sm">
                <div className="bs-component">
                    <ul className="navbar-nav nav-pills">
                        <li className="nav-link">
                            <NavLink
                                className='nav-link active'
                                to="/Home/Usuario/NovoUsuario">
                                <label> Dados Usuário </label>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}
import React, { Component } from "react";
import NavbarEntidade from './navbarEntidade';
import RotasEntidade from './rotasEntidade';

export default class NavbarRotaEntidade extends Component {
    render() {
        return (
            <div>
                <NavbarEntidade />
                <RotasEntidade />
            </div>
        );
    }
}
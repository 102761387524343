import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { TituloEntidade } from './tituloForm'

import {
    changeQuantidadeEmpresaHsPonto,
    changeQuantidadeFuncionarioHsPonto,
    changeQuantidadeUsuarioHsPonto,
    changeQuantidadeEquipamentoHsPonto,
    changeDataFimLicencaHsPonto,
    changeSituacaoHsPonto,
} from '../actions/actionEntidadeHsPonto';

const ROLE_SYSTEM = "System";
const ROLE_ADM = "Admin";
const ROLE_User = "User";


Modal.setAppElement('#root');

class HSPonto extends Component {
    constructor(state) {
        super(state);
        this.state = {
            situacao: this.props.situacaoHsPonto,
            quantidadeEmpresas: this.props.quantidadeEmpresasHsPonto,
            quantidadeFuncionarios: this.props.quantidadeFuncionariosHsPonto,
            quantidadeUsuarios: this.props.quantidadeUsuariosHsPonto,
            quantidadeEquipamentos: this.props.quantidadeEquipamentosHsPonto,
            dataFimLicenca: this.props.dataFimLicencaHsPonto
        }

        this.atribuiSituacao = this.atribuiSituacao.bind(this);
        this.atribuiEmpresas = this.atribuiEmpresas.bind(this);
        this.atribuiFuncionarios = this.atribuiFuncionarios.bind(this);
        this.atribuiUsuarios = this.atribuiUsuarios.bind(this);
        this.atribuiEquipamentos = this.atribuiEquipamentos.bind(this);
        this.atribuiDataFimLicenca = this.atribuiDataFimLicenca.bind(this);
    }

    verificaRole() {
        switch (this.props.currentRole) {
            case ROLE_SYSTEM:
                return true;
            case ROLE_ADM:
                return true;
            case ROLE_User:
                return false;
            default:
                return false
        }
    }

    atribuiSituacao(event){
        let situacao = event.target.value;

        this.setState({ situacao });
        this.props.changeSituacaoHsPonto(situacao)
    }

    atribuiEmpresas(event){
        let quantidadeEmpresas = event.target.value;

        this.setState({ quantidadeEmpresas });
        this.props.changeQuantidadeEmpresaHsPonto(quantidadeEmpresas)
    }

    atribuiFuncionarios(event){
        let quantidadeFuncionarios = event.target.value;

        this.setState({ quantidadeFuncionarios });
        this.props.changeQuantidadeFuncionarioHsPonto(quantidadeFuncionarios)
    }

    atribuiUsuarios(event){
        let quantidadeUsuarios = event.target.value;

        this.setState({ quantidadeUsuarios });
        this.props.changeQuantidadeUsuarioHsPonto(quantidadeUsuarios)
    }

    atribuiEquipamentos(event){
        let quantidadeEquipamentos = event.target.value;

        this.setState({ quantidadeEquipamentos });
        this.props.changeQuantidadeEquipamentoHsPonto(quantidadeEquipamentos)
    }

    atribuiDataFimLicenca(event){
        let dataFimLicenca = event.target.value;

        this.setState({ dataFimLicenca });
        this.props.changeDataFimLicencaHsPonto(dataFimLicenca)
    }
    
    render() {
        let disabled = this.verificaRole() ? false : true;

        return (
            <>
                <TituloEntidade titulo="HSPonto"/>
                <div className="form-row margemFormulario">
                    <div className="form-group col-12 col-sm-3">
                        <label> Situação: </label>
                        <select
                            disabled={disabled}
                            id='situacaoEntidade'
                            name='situacaoEntidade'
                            className={this.state.situacao === "Selecione" ? 'form-control is-invalid' : 'form-control'}
                            value={this.state.situacao}
                            onChange={this.atribuiSituacao}>
                            <option defaultValue> Selecione</option>
                            <option value='0'>Bloqueado</option>
                            <option value='1'>Liberado</option>
                        </select>
                        <div className='invalid-feedback'>
                            {this.state.situacao === "Selecione" ? "Selecione uma opção" : null}
                        </div>
                    </div>
                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Empresas: </label>
                        <input
                            disabled={disabled}
                            type="number"
                            id="quantidadeEmpresas"
                            name="quantidadeEmpresas"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeEmpresas}
                            onChange={this.atribuiEmpresas} />
                    </div>

                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Funcionarios: </label>
                        <input
                            disabled={disabled}
                            type="number"
                            id="quantidadeFuncionarios"
                            name="quantidadeFuncionarios"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeFuncionarios}
                            onChange={this.atribuiFuncionarios} />
                    </div>

                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Usuários: </label>
                        <input
                            type="number"
                            id="quantidadeUsuarios"
                            name="quantidadeUsuarios"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeUsuarios}
                            onChange={this.atribuiUsuarios}
                            disabled={disabled} />
                    </div> 
                </div>
                <div className="form-row margemFormulario">
                    <div className="form-group col-12 col-sm-3">
                        <label> Data final da licença </label>
                        <input
                            type="date"
                            id="dataFimLicenca"
                            name="dataFimLicenca"
                            value={this.state.dataFimLicenca || ''}
                            onChange={this.atribuiDataFimLicenca}
                            className='form-control'
                            autoComplete='off'
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-group col-12 col-sm-3">
                        <label> Quantidade de Equipamentos: </label>
                        <input
                            type="number"
                            id="quantidadeEquipamentos"
                            name="quantidadeEquipamentos"
                            className='form-control'
                            autoComplete="off"
                            value={this.state.quantidadeEquipamentos}
                            onChange={this.atribuiEquipamentos}
                            disabled={disabled} />
                    </div> 
                </div>
            </>
        );
    }
}

const mapStateToProp = (state) => ({
    currentRole: state.dadosRole.currentRole,

    situacaoHsPonto: state.dadosEntidade.situacaoHsPonto,
    quantidadeEmpresasHsPonto: state.dadosEntidade.quantidadeEmpresasHsPonto,
    quantidadeFuncionariosHsPonto: state.dadosEntidade.quantidadeFuncionariosHsPonto,
    quantidadeUsuariosHsPonto: state.dadosEntidade.quantidadeUsuariosHsPonto,
    quantidadeEquipamentosHsPonto: state.dadosEntidade.quantidadeEquipamentosHsPonto,
    dataFimLicencaHsPonto: state.dadosEntidade.dataFimLicencaHsPonto,
});

const mapActionstoProps = {
    changeQuantidadeEmpresaHsPonto,
    changeQuantidadeFuncionarioHsPonto,
    changeQuantidadeUsuarioHsPonto,
    changeQuantidadeEquipamentoHsPonto,
    changeDataFimLicencaHsPonto,
    changeSituacaoHsPonto,
}

export default connect(mapStateToProp, mapActionstoProps)(HSPonto);
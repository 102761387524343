import React, { Component } from "react";

export class TituloEntidade extends Component {

    render() {
        return (
            <>
                <br/>
                <p className="title">{this.props.titulo}</p>
            </>
        );
    }
}

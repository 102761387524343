const initialState = {
    nomeEntidade: '',
    emailEntidade: '',
    cnpj: '',
    inicioContrato: Date.now(),
    recuperaUsuario: false,
    idEntidade: '',
    idEntidadeAcesso: '',

    quantidadeEmpresasHsMobile: 0,
    quantidadeFuncionariosHsMobile: 0,
    quantidadeUsuariosHsMobile: 0,
    dataFimLicencaHsMobile: null,
    situacaoHsMobile: '',

    quantidadeEmpresasHsPonto: 0,
    quantidadeFuncionariosHsPonto: 0,
    quantidadeUsuariosHsPonto: 0,
    quantidadeEquipamentosHsPonto: 0,
    dataFimLicencaHsPonto: null,
    situacaoHsPonto: '',

    quantidadeEmpresasHsCom: 0,
    quantidadeFuncionariosHsCom: 0,
    quantidadeUsuariosHsCom: 0,
    quantidadeEquipamentosHsCom: 0,
    dataFimLicencaHsCom: null,
    situacaoHsCom: '',
}

const adicionaEntidade = (state = initialState, action) => {
    switch (action.type) {
        case 'changeNomeEntidade':
            return {...state, nomeEntidade: action.payload.nomeEntidade }
        case 'changeEmailEntidade':
            return {...state, emailEntidade: action.payload.emailEntidade }
        case 'changeCnpjEntidade':
            return {...state, cnpj: action.payload.cnpj }
        case 'changeInicioContratoEntidade':
            return {...state, inicioContrato: action.payload.inicioContrato }
        case 'changeRecuperaUsuarioEntidade':
            return {...state, recuperaUsuario: action.payload.recuperaUsuario }
        case 'changeIdEntidade':
            return {...state, idEntidade: action.payload.idEntidade }
        case 'changeIdAcessoEntidade':
            return {...state, idEntidadeAcesso: action.payload.idEntidadeAcesso }

        //HSMobile
        case 'changeQuantidadeEmpresaHsMobile':
            return {...state, quantidadeEmpresasHsMobile: action.payload.quantidadeEmpresasHsMobile }
        case 'changeQuantidadeFuncionarioHsMobile':
            return {...state, quantidadeFuncionariosHsMobile: action.payload.quantidadeFuncionariosHsMobile }
        case 'changeQuantidadeUsuarioHsMobile':
            return {...state, quantidadeUsuariosHsMobile: action.payload.quantidadeUsuariosHsMobile }
        case 'changeSituacaoHsMobile':
            return {...state, situacaoHsMobile: action.payload.situacaoHsMobile }
        case 'changeDataFimLicencaHsMobile':
            return {...state, dataFimLicencaHsMobile: action.payload.dataFimLicencaHsMobile }

        //HSPonto
        case 'changeQuantidadeEmpresaHsPonto':
            return {...state, quantidadeEmpresasHsPonto: action.payload.quantidadeEmpresasHsPonto }
        case 'changeQuantidadeFuncionarioHsPonto':
            return {...state, quantidadeFuncionariosHsPonto: action.payload.quantidadeFuncionariosHsPonto }
        case 'changeQuantidadeUsuarioHsPonto':
            return {...state, quantidadeUsuariosHsPonto: action.payload.quantidadeUsuariosHsPonto }
        case 'changeQuantidadeEquipamentoHsPonto':
            return {...state, quantidadeEquipamentosHsPonto: action.payload.quantidadeEquipamentosHsPonto }
        case 'changeSituacaoHsPonto':
            return {...state, situacaoHsPonto: action.payload.situacaoHsPonto }
        case 'changeDataFimLicencaHsPonto':
            return {...state, dataFimLicencaHsPonto: action.payload.dataFimLicencaHsPonto }

        //HSCom
        case 'changeQuantidadeEmpresaHsCom':
            return {...state, quantidadeEmpresasHsCom: action.payload.quantidadeEmpresasHsCom }
        case 'changeQuantidadeFuncionarioHsCom':
            return {...state, quantidadeFuncionariosHsCom: action.payload.quantidadeFuncionariosHsCom }
        case 'changeQuantidadeUsuarioHsCom':
            return {...state, quantidadeUsuariosHsCom: action.payload.quantidadeUsuariosHsCom }
        case 'changeQuantidadeEquipamentoHsCom':
            return {...state, quantidadeEquipamentosHsCom: action.payload.quantidadeEquipamentosHsCom }
        case 'changeSituacaoHsCom':
            return {...state, situacaoHsCom: action.payload.situacaoHsCom }
        case 'changeDataFimLicencaHsCom':
            return {...state, dataFimLicencaHsCom: action.payload.dataFimLicencaHsCom }

        case 'changeEntidadeInitialState':
            return initialState;
            
        default:
            return state;
    }
};

export default adicionaEntidade;
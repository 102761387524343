import React, { Component } from 'react';
import './App.css';
import Login from './componentes/login/mainLogin';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './componentes/estrutura/Home';
import EsqueceuSenha from './componentes/login/esqueceuSenha';
import AlterarSenha from './componentes/login/alteraSenha';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './configureStore';

class App extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/EsqueceuSenha" component={EsqueceuSenha} />
              <Route path="/NovaSenha" component={AlterarSenha} />
              <Route path="/Home" component={Home} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;

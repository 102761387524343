import React, { Component } from 'react';
import RotasBotaoUsuario from './rotas_botaoUsuario';

export default class MainUsuario extends Component {
    render() {
        return (
            <div className="col-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                {/* <div className="card cor-fundo-card"> */}
                    <div className="margin-interna">
                        <RotasBotaoUsuario />
                    </div>
                {/* </div> */}
            </div>
        );
    }
}
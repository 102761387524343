import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FormEntidade from './formEntidade';
import HSMobile from './hsMobile';
import HSPonto from './hsPonto';
import HSCom from './hsCom';

const RotasEntidade = () => (
    <Switch>
        <Route exact path='/Home/Entidade/NovaEntidade' component={FormEntidade} />
        <Route exact path='/Home/Entidade/NovaEntidade/HSMobile' component={HSMobile} />
        <Route exact path='/Home/Entidade/NovaEntidade/HSPonto' component={HSPonto} />
        <Route exact path='/Home/Entidade/NovaEntidade/HSCom' component={HSCom} />
    </Switch>
);

export default RotasEntidade;
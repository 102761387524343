export const changeQuantidadeEmpresaHsPonto = (quantidadeEmpresasHsPonto) => {
    return {
        type: 'changeQuantidadeEmpresaHsPonto',
        payload: { quantidadeEmpresasHsPonto }
    }
}

export const changeQuantidadeFuncionarioHsPonto = (quantidadeFuncionariosHsPonto) => {
    return {
        type: 'changeQuantidadeFuncionarioHsPonto',
        payload: { quantidadeFuncionariosHsPonto }
    }
}

export const changeQuantidadeUsuarioHsPonto = (quantidadeUsuariosHsPonto) => {
    return {
        type: 'changeQuantidadeUsuarioHsPonto',
        payload: { quantidadeUsuariosHsPonto }
    }
}

export const changeQuantidadeEquipamentoHsPonto = (quantidadeEquipamentosHsPonto) => {
    return {
        type: 'changeQuantidadeEquipamentoHsPonto',
        payload: { quantidadeEquipamentosHsPonto }
    }
}

export const changeDataFimLicencaHsPonto = (dataFimLicencaHsPonto) => {
    return {
        type: 'changeDataFimLicencaHsPonto',
        payload: { dataFimLicencaHsPonto }
    }
}

export const changeSituacaoHsPonto = (situacaoHsPonto) => {
    return {
        type: 'changeSituacaoHsPonto',
        payload: { situacaoHsPonto }
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import CheckBox from 'react-simple-checkbox';
import { connect } from 'react-redux';
import {
    changeNomeEntidade,
    changeEmailEntidade,
    changeCnpjEntidade,
    changeInicioContratoEntidade,
    changeRecuperaUsuarioEntidade,
    changeIdEntidade,
    changeIdAcessoEntidade,
} from '../actions/actionEntidade';

import { changeEntidadeInitialState } from '../actions/actions'

import  HsMobile  from './hsMobile'
import  HSPonto  from './hsPonto'
import  HSCom  from './hsCom'

const ROLE_SYSTEM = "System";
const ROLE_ADM = "Admin";
const ROLE_User = "User";

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT'
});

Modal.setAppElement('#root');

class FormEntidade extends Component {
    constructor(state) {
        super(state);
        this.state = {
            nomeEntidade: this.props.nomeEntidade,
            emailEntidade: this.props.emailEntidade,
            CNPJ: this.props.cnpj,
            inicioContrato: this.props.inicioContrato,
            IDEntidade: this.props.idEntidade,
            recuperaUsuario: this.props.recuperaUsuario,
            idEntidadeAcesso: this.props.idEntidadeAcesso,
            sistema: "",

            erro: false,
            cancela: false,
            sucesso: false,
            refresh: false,
            loading: false,

            exibirHsMobile: (this.props.situacaoHsMobile && this.props.situacaoHsMobile !== "Selecione") || this.props.quantidadeEmpresasHsMobile || this.props.quantidadeFuncionariosHsMobile || this.props.situacaoHsMobile,
            exibirHsPonto: (this.props.situacaoHsPonto && this.props.situacaoHsPonto !== "Selecione") || this.props.quantidadeEmpresasHsPonto || this.props.quantidadeFuncionariosHsPonto || this.props.quantidadeUsuariosHsPonto || this.props.quantidadeEquipamentosHsPonto,
            exibirHsCom: (this.props.situacaoHsCom && this.props.situacaoHsCom !== "Selecione") || this.props.quantidadeEmpresasHsCom || this.props.quantidadeFuncionariosHsCom || this.props.quantidadeUsuariosHsCom || this.props.quantidadeEquipamentosHsCom,

            //Mensagens erro
            CNPJ_erro: '',
            nomeEntidade_erro: '',
            emailEntidade_erro: '',
            tipoErro: []
        }

        this.atribuiNome = this.atribuiNome.bind(this);
        this.validaNome = this.validaNome.bind(this);

        this.atribuiEmail = this.atribuiEmail.bind(this);
        this.validaEmail = this.validaEmail.bind(this);

        this.atribuiCNPJ = this.atribuiCNPJ.bind(this);
        this.validaCNPJ = this.validaCNPJ.bind(this);
        this.atribuiInicioContrato = this.atribuiInicioContrato.bind(this);
        this.atribuiRecuperaUsuario = this.atribuiRecuperaUsuario.bind(this);

        this.atribuiSistema = this.atribuiSistema.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.enviaDados = this.enviaDados.bind(this);
        this.requisicaoEnvio = this.requisicaoEnvio.bind(this);
        this.requisicaoAlteracao = this.requisicaoAlteracao.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);        
        
        this.defineOpcaoSistema = this.defineOpcaoSistema.bind(this);
        this.limpaDadosEntidade = this.limpaDadosEntidade.bind(this);

        this.getHsMobile = this.getHsMobile.bind(this);
        this.getHsPonto = this.getHsPonto.bind(this);
        this.getHsCom = this.getHsCom.bind(this);
    }

    getHsMobile(){
        if ((this.props.situacaoHsMobile && this.props.situacaoHsMobile !== "Selecione") 
            || this.props.quantidadeEmpresasHsMobile || this.props.quantidadeFuncionariosHsMobile 
            || this.props.situacaoHsMobile)
        {
            return {
                "Situacao": this.props.situacaoHsMobile === '' ? -1 : this.props.situacaoHsMobile,
                "QuantidadeEmpresas": this.props.quantidadeEmpresasHsMobile,
                "QuantidadeFuncionarios": this.props.quantidadeFuncionariosHsMobile,
                "QuantidadeUsuarios": this.props.quantidadeUsuariosHsMobile,
                "DataFimLicenca": this.props.dataFimLicencaHsMobile,
            }
        }

        return null;
    }

    getHsPonto(){
        if ((this.props.situacaoHsPonto && this.props.situacaoHsPonto !== "Selecione") 
            || this.props.quantidadeEmpresasHsPonto || this.props.quantidadeFuncionariosHsPonto 
            || this.props.quantidadeUsuariosHsPonto || this.props.quantidadeEquipamentosHsPonto)
        {
            return {
                "Situacao": this.props.situacaoHsPonto === '' ? -1 : this.props.situacaoHsPonto,
                "QuantidadeEmpresas": this.props.quantidadeEmpresasHsPonto,
                "QuantidadeFuncionarios": this.props.quantidadeFuncionariosHsPonto,
                "QuantidadeUsuarios": this.props.quantidadeUsuariosHsPonto,
                "QuantidadeEquipamentos": this.props.quantidadeUsuariosHsPonto,
                "DataFimLicenca": this.props.dataFimLicencaHsPonto,
            }
        }

        return null;
    }

    getHsCom(){
        if ((this.props.situacaoHsCom && this.props.situacaoHsCom !== "Selecione") 
            || this.props.quantidadeEmpresasHsCom || this.props.quantidadeFuncionariosHsCom 
            || this.props.quantidadeUsuariosHsCom || this.props.quantidadeEquipamentosHsCom)
        {
            return {
                "Situacao": this.props.situacaoHsCom === '' ? -1 : this.props.situacaoHsCom,
                "QuantidadeEmpresas": this.props.quantidadeEmpresasHsCom,
                "QuantidadeFuncionarios": this.props.quantidadeFuncionariosHsCom,
                "QuantidadeUsuarios": this.props.quantidadeUsuariosHsCom,
                "QuantidadeEquipamentos": this.props.quantidadeUsuariosHsCom,
                "DataFimLicenca": this.props.dataFimLicencaHsCom,
            }
        }

        return null;
    }

    atribuiRecuperaUsuario(check) {
        var recuperaUsuario = check;
        this.setState({ recuperaUsuario })

        this.props.changeRecuperaUsuarioEntidade(recuperaUsuario);
    }

    atribuiNome(event) {
        let nomeEntidade = event.target.value;

        this.setState({ nomeEntidade })
        this.props.changeNomeEntidade(nomeEntidade);
    }

    validaNome() {
        const nomeEntidade = this.state.nomeEntidade;

        let nomeEntidade_erro = nomeEntidade.length >= 4 && nomeEntidade.length <= 80 ? null : 'Campo deve conter entre 4 a 80 caracteres'
        this.setState({ nomeEntidade_erro });
    }

    atribuiEmail(event) {
        let emailEntidade = event.target.value;

        this.setState({ emailEntidade })
        this.props.changeEmailEntidade(emailEntidade)
    }

    validaEmail() { //TO DO: ajustar REGEX
        //https://pt.stackoverflow.com/questions/1386/express%C3%A3o-regular-para-valida%C3%A7%C3%A3o-de-e-mail
        let emailEntidade = this.state.emailEntidade;
        //let formatEmail = /^([a-zA-Z0-9_\-.]+)@(([a-zA-Z0-9])([.][a-zA-Z])+)$/i; //necessita de .br
        let formatEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)*$/i;

        let emailEntidade_erro = !emailEntidade.match(formatEmail) ? 'E-mail inválido' : null

        this.setState({ emailEntidade_erro });
    }

    atribuiCNPJ(event) {
        let CNPJ = event.target.value;

        CNPJ = CNPJ.replace(/[^\d]+/g, '');

        this.setState({ CNPJ });
        this.props.changeCnpjEntidade(CNPJ)
    }

    validaCNPJ() {
        let CNPJ = this.state.CNPJ;

        CNPJ = CNPJ.replace(/[^\d]+/g, '');

        var validador_cnpj = true;

        if (CNPJ === "00000000000000" ||
            CNPJ === "11111111111111" ||
            CNPJ === "22222222222222" ||
            CNPJ === "33333333333333" ||
            CNPJ === "44444444444444" ||
            CNPJ === "55555555555555" ||
            CNPJ === "66666666666666" ||
            CNPJ === "77777777777777" ||
            CNPJ === "88888888888888" ||
            CNPJ === "99999999999999") {

            validador_cnpj = false;
        }
        else {
            const multiplicador1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            const multiplicador2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            let soma = 0;
            let digito1;
            let digito2;

            for (let i = 0; i < 12; i++) {
                soma += multiplicador1[i] * parseInt(CNPJ[i]);
            }

            let resto = soma % 11;

            if (resto < 2) {
                digito1 = 0;
            }
            else {
                digito1 = 11 - resto;
            }

            soma = 0;

            for (let i = 0; i < 13; i++) {
                soma += multiplicador2[i] * parseInt(CNPJ[i]);
            }

            resto = soma % 11;

            if (resto < 2) {
                digito2 = 0;
            }
            else {
                digito2 = 11 - resto;
            }
            if (parseInt(CNPJ[12]) === digito1 && parseInt(CNPJ[13]) === digito2) {
                validador_cnpj = true;
            }
            else {
                validador_cnpj = false;
            }
        }

        if (validador_cnpj) {
            this.pontuacaoCNPJ(CNPJ);
            this.setState({ CNPJ_erro: '' });
        }
        else {
            this.setState({ CNPJ_erro: "CNPJ Inválido" });
        }
    }

    pontuacaoCNPJ(CNPJ) {
        CNPJ = CNPJ.replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, "$1.$2.$3/$4-$5");

        this.setState({ CNPJ });
    }

    atribuiInicioContrato(event) {
        var inicioContrato = event.target.value;
        this.setState({ inicioContrato });

        this.props.changeInicioContratoEntidade(inicioContrato)
    }

    enviaDados(event) {
        event.preventDefault();

        this.setState({ loading: true });

        if (this.state.IDEntidade === '') {
            this.requisicaoEnvio();
        }
        else {
            this.requisicaoAlteracao();
        }
    }

    requisicaoEnvio() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        var hsMobile = this.getHsMobile();
        var hsPonto = this.getHsPonto();
        var hsCom = this.getHsCom();

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'entidades',
            {
                "Nome": this.props.nomeEntidade,
                "Cnpj": this.props.cnpj,
                "Email": this.props.emailEntidade,
                "InicioContrato": this.props.inicioContrato,
                "RecuperaUsuario": this.props.recuperaUsuario,
                "IDEntidadeAcesso": this.props.idEntidadeAcesso,
                "HsMobile": hsMobile,
                "HsPonto": hsPonto,
                "HsCom": hsCom
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        mensagemSucesso: 'Cadastro feito com sucesso!',
                        loading: false
                    });
                    this.ModalSucesso();
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.POST);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoAlteracao() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        var hsMobile = this.getHsMobile();

        var hsPonto = this.getHsPonto();

        var hsCom = this.getHsCom();

        axios.put(process.env.REACT_APP_URL_REQUISICOES + 'entidades/' + this.props.idEntidade,
            {
                "IDEntidade": this.props.idEntidade,
                "Nome": this.props.nomeEntidade,
                "Cnpj": this.props.cnpj,
                "Email": this.props.emailEntidade,
                "InicioContrato": this.props.inicioContrato,
                "RecuperaUsuario": this.props.recuperaUsuario,
                "IDEntidadeAcesso": this.props.idEntidadeAcesso,
                "HsMobile": hsMobile,
                "HsPonto": hsPonto,
                "HsCom": hsCom
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        mensagemSucesso: 'Alteração feita com sucesso!',
                        loading: false
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.PUT);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token');
        let refreshToken = cookie.get('refreshToken');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            // console.log("Requisição não identificada");
                            break;
                    }
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    atribuiTipoPesquisa(event) {
        // Atribui o tipo de pesquisa que será usada no filtro
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa, pesquisa: '' });
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso });

        this.limpaDadosEntidade();
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
        this.limpaDadosEntidade();
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    verificaRole() {
        switch (this.props.currentRole) {
            case ROLE_SYSTEM:
                return true;
            case ROLE_ADM:
                return true;
            case ROLE_User:
                return false;
            default:
                return false
        }
    }

    atribuiSistema(event){
        var opcaoSelecionada = event.target.value;

        switch(opcaoSelecionada){
            case 'HSMobile':
                this.setState({ exibirHsMobile: true, sistema: opcaoSelecionada});
                break;
            case 'HSPonto':
                this.setState({ exibirHsPonto: true, sistema: opcaoSelecionada});
                break;
            case 'HSCom':
                this.setState({ exibirHsCom: true, sistema: opcaoSelecionada});
                break;        
            default:
                this.setState({ sistema: "Selecione"});
        }
    }

    defineOpcaoSistema(){
        var array = [];

        if(!this.state.exibirHsMobile){
            array.push('HSMobile')
        }

        if(!this.state.exibirHsPonto){
            array.push('HSPonto')
        }

        if(!this.state.exibirHsCom){
            array.push('HSCom')
        }

        return array.map((item, index) => {
           return <option key={index}>{item}</option>;
        })
    }

    limpaDadosEntidade(){
        this.props.changeEntidadeInitialState(); 
    }

    render() {
        let disabled = this.verificaRole() ? false : true;
        let opcoesSistemas = this.defineOpcaoSistema();
        let exibeSistemas = !this.state.exibirHsMobile || !this.state.exibirHsPonto || !this.state.exibirHsCom;

        let componenteSistema = 
            <div className="form-row">
                <div className="form-group col-12 col-sm-3">
                    <label> Sistemas: </label>
                    <select
                        disabled={disabled}
                        required='required'
                        id='sistemasEntidade'
                        name='sistemasEntidade'
                        className={this.state.sistema === "Selecione" ? 'form-control is-invalid' : 'form-control'}
                        value={-1}
                        onChange={this.atribuiSistema}
                        >
                        <option value={-1} disabled="disabled"> Selecione</option>
                        {opcoesSistemas}
                    </select>
                    <div className='invalid-feedback'>
                        {this.state.sistema === "Selecione" ? "Selecione uma opção" : null}
                    </div>
                </div>
            </div>;

        return (
            <div>
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    onRequestClose={this.ModalCancelamento}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/Entidade'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal" onClick={this.limpaDadosEntidade}>Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    onRequestClose={this.ModalSucesso}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/Entidade">
                                    <button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    onRequestClose={this.ModalErro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    onRequestClose={this.ModalRefresh}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Enviando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>

                <form onSubmit={this.enviaDados}>
                    <div className="form-row margemFormulario">
                        <div className="form-group col-12 col-sm-2">
                            <label> ID Acesso </label>
                            <input
                                type="text"
                                id="idEntidadeAcesso"
                                name="idEntidadeAcesso"
                                value={this.state.idEntidadeAcesso}
                                className='form-control'
                                disabled
                            />
                            <div className='invalid-feedback'>{this.state.nomeEntidade_erro}</div>
                        </div>
                        <div className="form-group col-12 col-sm-5">
                            <label> Nome </label>
                            <input
                                type="text"
                                id="nomeEntidade"
                                name="nomeEntidade"
                                value={this.state.nomeEntidade}
                                onChange={this.atribuiNome}
                                onBlur={this.state.nomeEntidade !== '' ? this.validaNome : null}
                                maxLength='80'
                                className={this.state.nomeEntidade_erro ? 'form-control is-invalid' : 'form-control'}
                                required='required'
                                autoComplete='off'
                                disabled={disabled}
                            />
                            <div className='invalid-feedback'>{this.state.nomeEntidade_erro}</div>
                        </div>

                        <div className="form-group col-12 col-sm-5">
                            <label> Email </label>
                            <input
                                type="email"
                                id="emailEntidade"
                                name="emailEntidade"
                                value={this.state.emailEntidade}
                                onChange={this.atribuiEmail}
                                onBlur={this.state.emailEntidade !== '' ? this.validaEmail : null}
                                className={this.state.emailEntidade_erro ? 'form-control is-invalid' : 'form-control'}
                                autoComplete='off'
                                disabled={disabled}
                            />
                            <div className='invalid-feedback'>{this.state.emailEntidade_erro}</div>
                        </div>
                    </div>

                    <div className="form-row margemFormulario">
                        <div className="form-group col-12 col-sm-4">
                            <label> CNPJ </label>
                            <input
                                type="text"
                                id="CNPJ"
                                name="CNPJ"
                                value={this.state.CNPJ}
                                onChange={this.atribuiCNPJ}
                                onBlur={this.state.CNPJ !== '' ? this.validaCNPJ : null}
                                className={this.state.CNPJ_erro ? 'form-control is-invalid' : 'form-control'}
                                required='required'
                                autoComplete='off'
                                disabled={disabled}
                            />
                            <div className='invalid-feedback'>{this.state.CNPJ_erro}</div>
                        </div>

                        <div className="form-group col-12 col-sm-4">
                            <label> Inicio do contrato </label>
                            <input
                                type="date"
                                id="inicioContrato"
                                name="inicioContrato"
                                value={this.state.inicioContrato}
                                onChange={this.atribuiInicioContrato}
                                className='form-control'
                                autoComplete='off'
                                required='required'
                                disabled={disabled}
                            />
                        </div>

                        <div className="form-group col-12 col-sm-4 centralizaCheck">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="recuperaUsuario">Recuperar Usuário:</label>
                                </div>
                                <div className="col text-center">
                                    <CheckBox
                                        id='recuperaUsuario'
                                        size={4}
                                        tickSize={3}
                                        borderThickness={1}
                                        checked={this.state.recuperaUsuario}
                                        onChange={this.atribuiRecuperaUsuario}
                                        color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#999999' }}
                                        //backgroundColor: '#007bef' -> cor do fundo tickado
                                        //borderColor: '#007bef' -> cor da borda tickado
                                        //uncheckedBorderColor: '#ced4da' -> cor da borda sem estar tickado
                                        tickAnimationDuration={100}
                                        backAnimationDuration={100} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.exibirHsMobile ? <HsMobile /> : null}
                    {this.state.exibirHsPonto ? <HSPonto /> : null}
                    {this.state.exibirHsCom ? <HSCom /> : null}

                    {exibeSistemas ? componenteSistema : null}

                    {this.verificaRole() ?
                        <div className="form-row">
                            <div className="form-group col-6 text-right">
                                <button
                                    disabled={disabled}
                                    type="submit"
                                    className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                    Salvar
                            </button>
                            </div>
                            <div className="form-group col-6 text-left">
                                <button
                                    type="button"
                                    onClick={this.ModalCancelamento}
                                    className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                    Cancelar
                            </button>
                            </div>
                        </div>
                        :
                        <div className="form-row">
                            <div className="form-group col text-center">
                                <Link to='/Home/Entidade'>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm col-7 col-sm-2">
                                        Voltar
                                    </button>
                                </Link>
                            </div>
                        </div>
                    }
                </form>
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    currentRole: state.dadosRole.currentRole,

    nomeEntidade: state.dadosEntidade.nomeEntidade,
    emailEntidade: state.dadosEntidade.emailEntidade,
    cnpj: state.dadosEntidade.cnpj,
    inicioContrato: state.dadosEntidade.inicioContrato,
    recuperaUsuario: state.dadosEntidade.recuperaUsuario,
    idEntidade: state.dadosEntidade.idEntidade,
    idEntidadeAcesso: state.dadosEntidade.idEntidadeAcesso,

    situacaoHsMobile: state.dadosEntidade.situacaoHsMobile,
    quantidadeEmpresasHsMobile: state.dadosEntidade.quantidadeEmpresasHsMobile,
    quantidadeFuncionariosHsMobile: state.dadosEntidade.quantidadeFuncionariosHsMobile,
    dataFimLicencaHsMobile: state.dadosEntidade.dataFimLicencaHsMobile,
    quantidadeUsuariosHsMobile: state.dadosEntidade.quantidadeUsuariosHsMobile,

    situacaoHsPonto: state.dadosEntidade.situacaoHsPonto,
    quantidadeEmpresasHsPonto: state.dadosEntidade.quantidadeEmpresasHsPonto,
    quantidadeFuncionariosHsPonto: state.dadosEntidade.quantidadeFuncionariosHsPonto,
    quantidadeUsuariosHsPonto: state.dadosEntidade.quantidadeUsuariosHsPonto,
    dataFimLicencaHsPonto: state.dadosEntidade.dataFimLicencaHsPonto,
    quantidadeEquipamentosHsPonto: state.dadosEntidade.quantidadeEquipamentosHsPonto,

    situacaoHsCom: state.dadosEntidade.situacaoHsCom,
    quantidadeEmpresasHsCom: state.dadosEntidade.quantidadeEmpresasHsCom,
    quantidadeFuncionariosHsCom: state.dadosEntidade.quantidadeFuncionariosHsCom,
    quantidadeUsuariosHsCom: state.dadosEntidade.quantidadeUsuariosHsCom,
    dataFimLicencaHsCom: state.dadosEntidade.dataFimLicencaHsCom,
    quantidadeEquipamentosHsCom: state.dadosEntidade.quantidadeEquipamentosHsCom,
});

const mapActionstoProps = {
    changeNomeEntidade,
    changeEmailEntidade,
    changeCnpjEntidade,
    changeInicioContratoEntidade,
    changeRecuperaUsuarioEntidade,
    changeIdEntidade,
    changeIdAcessoEntidade,

    changeEntidadeInitialState,
}

export default connect(mapStateToProp, mapActionstoProps)(FormEntidade);
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FaPlus } from "react-icons/fa";

export default class NavbarEntidade extends Component {

    render() {
        return (
            <Link to={{
                pathname: '/Home/Entidade/NovaEntidade',
                state: {
                    nomeEntidade: '',
                    emailEntidade: '',
                    quantEmpresas: '',
                    quantFuncionarios: '',
                    quantUsuarios: '',
                    situacao: '',
                    CNPJ: '',
                    inicioContrato: '',
                    IDEntidade: '',
                    recuperaUser: false,
                    idEntidade: ''
                }
            }} >
                <button className="btn btn-outline-primary botaoParaFormulario">
                    <FaPlus size={20} className="icone" />
                </button>
            </Link>
        );
    }
}

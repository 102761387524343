import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TabelaEntidade from './tabela_entidade'; 
import NavbarRotaEntidade from './navBar_rotaEntidade';

const RotasBotaoEntidade = () => (
    <Switch>
        <Route exact path='/Home/Entidade' component={TabelaEntidade} />
        <Route path='/Home/Entidade/NovaEntidade' component={NavbarRotaEntidade} />
    </Switch>
);

export default RotasBotaoEntidade;
export const changeCurrentRole = (currentRole) => {
    return {
        type: 'changeCurrentRole',
        payload: { currentRole }
    };
}

export const changeInitialState = (currentRole) => {
    return {
        type: 'changeInitialState',
        payload: { currentRole: '' }
    };
}

export const changeLogout = () => {
    return {
        type: 'changeLogout',
        payload: { conectado: false, id: '', username: '' }
    }
}

export const changeUsername = (username) => {
    return {
        type: 'changeUsername',
        payload: { username }
    }
}

export const changeConectado = (conectado) => {
    return {
        type: 'changeConectado',
        payload: { conectado }
    };
}

export const changeInitialStateLogout = () => {
    return {
        type: 'changeInitialStateLogout'
    };
}

export const changeEntidadeInitialState = () => {
    return {
        type: 'changeEntidadeInitialState'
    };
}
import React, { Component } from "react";
import NavbarUsuario from './navbarUsuario';
import RotasUsuario from './rotasUsuario';

export default class NavbarRotaUsuario extends Component {
    render() {
        return (
            <div>
                <NavbarUsuario />
                <RotasUsuario />
            </div>
        );
    }
}